import { datadogLogs } from '@datadog/browser-logs';
import { HeroThankYou } from '@payfit/growth-platform-brand-system-v2/templates/HeroThankYou';
import { graphql } from 'gatsby';
import { useTracking } from 'libs/growth-platform-brand-system-v2/src/utils/tracking';
import { useScreenSize } from 'libs/growth-platform-brand-system-v2/src/utils/utils';
import { morphism } from 'morphism';
import React, { useEffect } from 'react';
import { TemplateBlocksZone } from '../../components/TemplateBlocksZone';
import Layout from '../../layout';
import { HeadWrapper } from '../../layout/HeadWrapper';
import { schemaBaseTemplate } from '../../morphismSchema/pages/schemaBaseTemplate';
import { schemaHeroThankYou } from '../../morphismSchema/templates/schemaHeroThankYou';
export const Head = ({ data, pageContext, location }: any) => (
  <HeadWrapper
    data={data}
    pageContext={pageContext}
    location={location}
    templateName="ThankYou"
    dataName={'datoCmsThankYouPage'}
    largestContentfullPaintImg={null}
  />
);
export const query = graphql`
  query DatoThankYouPage($locale: String, $id: String) {
    datoCmsThankYouPage(locale: $locale, id: { eq: $id }) {
      ...ThankYouPage
    }
  }
`;

const ThankYou = ({ data, pageContext }: any) => {
  const { trackingPage } = useTracking();

  const page: any = data.datoCmsThankYouPage;
  const screenSize = useScreenSize();
  const _data = morphism(schemaBaseTemplate, {
    ...page,
    screenSize,
    pageContext,
  });

  useEffect(() => {
    datadogLogs.logger.info('Thank you page', {
      submitted: (window as any).DEMO_FORM_SUBMITTED,
    });
  }, []);

  if (!page) return <></>;
  return (
    <Layout
      pageContext={pageContext}
      data={page}
      customHeader={page.header}
      templateName="ThankYou"
    >
      <HeroThankYou {...morphism(schemaHeroThankYou, page.hero[0])} />
      {_data?.blocks.length > 0 && <TemplateBlocksZone {..._data} />}
    </Layout>
  );
};

export default ThankYou;
